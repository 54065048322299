import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import AndroidIcon from "@mui/icons-material/Android";

import "./AboutUs.css";
import { OutlineButton, TickIconAnimate } from "./../../../components/Common";

const about = [
  {
    header: "Corporis voluptates",
    description:
      "Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip",
    icon: <NextWeekIcon />,
  },
  {
    header: "Corporis voluptates sit",
    description:
      "Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip",
    icon: <AccessAlarmsIcon />,
  },
  {
    header: "Corporis voluptates sit",
    description:
      "Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip",
    icon: <AddAlertIcon />,
  },
  {
    header: "Corporis voluptates sit",
    description:
      "Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip",
    icon: <AndroidIcon />,
  },
];

export default function AboutUs() {
  return (
    <Box className="primary section">
      <Container className="main-container about-main">
        <Grid container spacing={8}>
          <Grid item md={5}>
            <Stack spacing={4}>
              <Typography variant="h4" className="heading">
                Voluptatem dignissimos provident quasi
              </Typography>
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                vitae congue quam. Nullam in aliquam urna. Cras scelerisque
                ultrices laoreet. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Fusce vitae congue quam. Nullam in aliquam
                urna. Cras scelerisque ultrices laoreet.
              </Typography>
              <Box>
                <OutlineButton>
                  <Stack direction="row" spacing={5}>
                    About Us <NavigateNextIcon />
                  </Stack>
                </OutlineButton>
              </Box>
            </Stack>
          </Grid>
          <Grid item md={7} className="about-details-container">
            {/* <Grid container spacing={4}>
              {about.map((item) => {
                <Grid item md={6}>
                  <Stack spacing={1}>
                    <TickIconAnimate>{item.icon}</TickIconAnimate>
                    <Typography variant="h6" className="heading">
                      {item.header}
                    </Typography>
                    <Typography variant="body1" className="about-description">
                      {item.description}
                    </Typography>
                  </Stack>
                </Grid>;
              })}
            </Grid> */}
            <Grid container spacing={4} sx={{ marginLeft: "0px !important" }}>
              <Grid item md={6} className="about-detail-box">
                <Stack spacing={2} className="about-stack">
                  <TickIconAnimate>
                    <NextWeekIcon />
                  </TickIconAnimate>
                  <Typography variant="h6" className="heading">
                    Corporis voluptates sit
                  </Typography>
                  <Typography variant="body1" className="about-description">
                    Consequuntur sunt aut quasi enim aliquam quae harum pariatur
                    laboris nisi ut aliquip
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                md={6}
                // sx={{
                //   paddingLeft: { xs: "0px", md: "32px" },
                // }}
                className="about-detail-box"
              >
                <Stack spacing={2} className="about-stack">
                  <TickIconAnimate>
                    <AccessAlarmsIcon />
                  </TickIconAnimate>
                  <Typography variant="h6" className="heading">
                    Ullamco laboris nisi
                  </Typography>
                  <Typography variant="body1" className="about-description">
                    Consequuntur sunt aut quasi enim aliquam quae harum pariatur
                    laboris nisi ut aliquip
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                md={6}
                // sx={{
                //   paddingLeft: { xs: "0px", md: "32px" },
                // }}
                className="about-detail-box"
              >
                <Stack spacing={2} className="about-stack">
                  <TickIconAnimate>
                    <AddAlertIcon />
                  </TickIconAnimate>
                  <Typography variant="h6" className="heading">
                    Labore consequatur
                  </Typography>
                  <Typography variant="body1" className="about-description">
                    Consequuntur sunt aut quasi enim aliquam quae harum pariatur
                    laboris nisi ut aliquip
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                md={6}
                // sx={{
                //   paddingLeft: { xs: "0px", md: "32px" },
                // }}
                className="about-detail-box"
              >
                <Stack spacing={2} className="about-stack">
                  <TickIconAnimate>
                    <AndroidIcon />
                  </TickIconAnimate>
                  <Typography variant="h6" className="heading">
                    Beatae veritatis
                  </Typography>
                  <Typography variant="body1" className="about-description">
                    Consequuntur sunt aut quasi enim aliquam quae harum pariatur
                    laboris nisi ut aliquip
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
