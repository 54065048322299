import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import OfferCard1 from "../../img/offer/offer-card-1.png";
import OfferCard2 from "../../img/offer/offer-card-2.png";
import OfferCard3 from "../../img/offer/offer-card-3.png";
import OfferCard4 from "../../img/offer/offer-card-4.png";
import OfferCard5 from "../../img/offer/offer-card-5.png";
import OfferCard6 from "../../img/offer/offer-card-6.png";
import BrainOne from "../../img/offer/brain-1.png";
import BrainTwo from "../../img/offer/brain-2.png";
import BrainThree from "../../img/offer/brain-3.png";
import { DescriptionBody, Heading2, MarginWrapper } from "../Common";
import { ContainedButtonTwo } from "../Button/Button";
import "./OfferCard.css";

const services = [
  {
    images: OfferCard1,
    heading: "Online Teaching",
    description:
      "With online learning programs, parents can teach their kids anytime and anywhere.  ",
  },
  {
    images: OfferCard2,
    heading: "Live Classes",
    description:
      "Although live class ideas are already prevalent and established education business.",
  },
  {
    images: OfferCard3,
    heading: "Trained Teachers",
    description:
      "Teaching is a passion and experts want to share their expertise outside of the institutional sphere.",
  },
  {
    images: OfferCard4,
    heading: "Visual Concepts",
    description:
      "Learning by watching videos is better than reading long pages and bulky books. ",
  },
  {
    images: OfferCard5,
    heading: "Gamified Course",
    description: "Gamification immerses the students into the subject matter.",
  },
  {
    images: OfferCard6,
    heading: "Trained Teachers",
    description:
      "Teaching is a passion and experts want to share their expertise outside of the institutional sphere.",
  },
];
 
export default function Services() {
  return ( 
    <Container data-aos="fade-up" fixed={true}>
      <MarginWrapper>
        <Box className="offer-card-custom">
          {services.map((service, i) => {
            return (
              <Box className="offer-card" key={i}>
                <img
                  loading="lazy"
                  src={service.images}
                  style={{ width: "37%", marginRight: "15px" }}
                  alt=""
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: 'center'
                  }}
                >
                  <Heading2 variant="h3" fontSize="20px !important">
                    {service.heading}
                  </Heading2>
                  <DescriptionBody className="offer-description">
                    {service.description}
                  </DescriptionBody>
                  <ContainedButtonTwo
                    width="100%"
                    btnText="Book Free Trial Class"
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </MarginWrapper>
    </Container> 
  );
}