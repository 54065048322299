import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import "./Message.css";

import { LinkHover, OutlineButton } from "./../../../components/Common";

export default function Message() {
  return (
    <Stack spacing={10}>
      <Box className="message section">
        <Container className="main-container message-main">
          <Stack spacing={2} className="message-stack">
            <Typography variant="body1" className="heading">
              From our team
            </Typography>
            <Typography variant="h4" className="heading">
              Success is the sum of small efforts, repeated day in and day out.
            </Typography>
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: 56, height: 56 }}
            />
            <Typography variant="body1" className="heading">
              Tim Cook
            </Typography>
            <Typography variant="body2" className="faq-description">
              Web Developer, Untitled
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Box className="message section">
        <Container className="message-main">
          <Grid container spacing={5}>
            <Grid item md={6} xs={12}>
              <Stack spacing={2.3}>
                <Typography variant="h4" className="heading">
                  Join 10000+ Students
                </Typography>
                <Typography variant="body1" className="faq-description">
                  Stay in the loop with everything you need to know.
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={5}>
                <Grid item md={7} xs={12}>
                  <Stack spacing={2} sx={{ marginBottom: "5px" }}>
                    <Box>
                      <TextField
                        id="outlined-password-input"
                        label="Enter your email"
                        type="email"
                        className="email-textfield"
                      />
                    </Box>
                    <Typography variant="body2" className="faq-description">
                      We care about your data in our{" "}
                      <LinkHover>privacy policy</LinkHover>
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={5} xs={12}>
                  <Box>
                    <OutlineButton
                      height="3.3em"
                      color="rgb(2, 62, 138) !important"
                      border-color="rgb(2, 62, 138) !important"
                    >
                      Subscribe
                    </OutlineButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Container>
      </Box>
    </Stack>
  );
}
