import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import { TickIcon } from "./../../../components/Common";
import StudentPNG from "./../../../img/quality-bg.png";
import "./Quality.css";

export default function Quality() {
  return (
    <Box className="secondary section">
      <Container className="main-container quality-main">
        <Grid container>
          <Grid item md={6} className="quality-section-bg">
            <Box className="quality-bg-box">
              <img src={StudentPNG} alt="student-png" />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Stack spacing={4} className="qualities">
              <Typography variant="h4" className="heading">
                We Provide You a Comfortable Learning Experience
              </Typography>
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                sed iaculis elit. Ut eu purus vitae turpis commodo dictum at.
              </Typography>
              <Stack spacing={3} className="icon-stack">
                <Stack direction="row" spacing={3} alignItems="center">
                  <TickIcon />
                  <Typography variant="body1">
                    You work in a comfortable seat
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={3} alignItems="center">
                  <TickIcon />
                  <Typography variant="body1">In-Depth Explanation</Typography>
                </Stack>
                <Stack direction="row" spacing={3} alignItems="center">
                  <TickIcon />
                  <Typography variant="body1">
                    Motivation and Accountability
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
