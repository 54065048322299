import React from 'react';
import './footer.css';
import { experimentalStyled as styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Logo from '../../img/logo.png';
import Favicon from '../../img/favicon.ico';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import HouseIcon from '@mui/icons-material/House';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import IconButton from '@mui/material/IconButton';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import Container from '@mui/material/Container';
// import Link from '@mui/material/Link';
import {Link} from "react-router-dom"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));  

const socials = [<FacebookIcon />, <InstagramIcon />, <TwitterIcon />];

const quicklinks = [
    {lable : 'Home', icon : <HouseIcon />,link:"/"}, 
    {lable : 'About Us', icon : <VerifiedOutlinedIcon />,link:"/"},
    {lable : 'Meet Founder', icon : <AutoAwesomeOutlinedIcon />,link:"/meet-founder"},
    {lable : 'Our Certificates', icon : <MilitaryTechOutlinedIcon />,link:"/"},
    {lable : 'Courses', icon : <SchoolOutlinedIcon />,link:"/"},
    {lable : 'Gallery', icon : <CollectionsOutlinedIcon />,link:"/"},
    {lable : 'Contact Us', icon : <LocalPhoneOutlinedIcon />,link:"/contact-us"}
];

const morelinks = [
    {lable : 'Careers', icon : <LaunchOutlinedIcon />},
    {lable : 'Teacher Login', icon : <LaunchOutlinedIcon />}, 
    {lable : 'Student Login', icon : <LaunchOutlinedIcon />},
    {lable : 'Register Now', icon : <LaunchOutlinedIcon />},
    {lable : 'Terms & Conditions', icon : <LaunchOutlinedIcon />},
    {lable : 'Privacy Policy', icon : <LaunchOutlinedIcon />}
]; 

const morelinksnew = [
    {lable : 'Help Desk', icon : <LaunchOutlinedIcon />},
    {lable : 'Complaint Redressal', icon : <LaunchOutlinedIcon />}, 
    {lable : 'Apply For Teacher Role', icon : <LaunchOutlinedIcon />},
    {lable : 'Help Desk', icon : <LaunchOutlinedIcon />},
]; 

// 
function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 500,
    });
    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        '#back-to-top-anchor',
      );
  
      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
    };
    return (
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000}}
        >
          {children}
        </Box>
      </Fade>
    );
} 
ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const Footer = (props) => {
  return (
    <Fade in timeout={800}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} className="footer">
            <CssBaseline />
            <Grid container spacing={2} px={2} py={2} direction="row" justifyContent="center" alignItems="center" sx={{backgroundColor: '#ff9b56', boxShadow: '0 12px 28px 0 rgba(0,0,0,.2), 0 2px 4px 0 rgba(0,0,0,.1), inset 0 0 0 1px hsla(0,0%,100%,.05)'}}>
                <Grid item xs={12} sm={12} md={8} className='aligncenter'>
                    <Typography sx={{color: '#ffffff', fontWeight: 'bold'}} variant="h6" component="h6">
                        Memory Power is the top coding learning platform for young minds:
                    </Typography> 
                </Grid>
                <Grid item xs={12} sm={12} md={2} className='aligncenter'>
                    <Button variant="contained" size="large" sx={{color: '#ff9b56', textTransform: 'capitalize', backgroundColor: '#ffffff', fontWeight: '600'}} href='https://memorypowerbackenddashboard.rabs.support/register' target="_blank">
                        <EventAvailableIcon />&nbsp;Book a free trial
                    </Button>
                </Grid>
            </Grid>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} spacing={{ xs: 2, md: 2 }} py={5} px={{xs: 3, md: 4}} direction="row" justifyContent="space-evenly" alignItems="top"> 
                <Grid item xs={12} sm={12} md={4}>
                    <img src={Logo} alt="logo" width={'200px'} />
                    <Typography mt={1} mb={2} sx={{color: '#ffffff', fontSize: '16px', lineHeight: '1.5'}} variant="p" component="div">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </Typography> 
                    <Button variant="contained" size="medium" sx={{color: '#ffffff', textTransform: 'capitalize', background: '#95bc17'}}>Read More..</Button>
                    <br />
                    <Box mt={2}> 
                        {socials.map((val, k) => (
                            <IconButton key={k} sx={{color: 'rgb(249 148 211)'}} >
                                {val}
                            </IconButton> 
                        ))}
                    </Box>
                </Grid> 
                <Grid item xs={12} sm={12} md={2} >
                    <Typography mt={1} variant="h6" sx={{color: '#ffffff'}} gutterBottom>
                        <LinkOutlinedIcon sx={{position: 'relative', top: '4px', marginLeft: '0.5rem', marginRight: '1rem'}} />Important Links
                    </Typography>
                    <Divider sx={{borderColor: '#ffffff'}} />
                    <List>
                        {quicklinks.map((i, k) => (
                            <ListItem key={k} disablePadding component={Link} to={i.link}>
                                <ListItemButton sx={{ textTransform: 'capitalize', fontSize: '14px', color: '#ffffff', textAlign: 'left', py: 1, px: 2}}>
                                    <ListItemIcon sx={{color: '#ffffff'}}>
                                        {i.icon}
                                    </ListItemIcon>
                                    <ListItemText sx={{marginLeft: '-0.8rem'}} primary={i.lable} />
                                </ListItemButton>
                            </ListItem> 
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} sm={12} md={2} >
                    <Typography mt={1} variant="h6" sx={{color: '#ffffff'}} gutterBottom>
                        <LinkOutlinedIcon sx={{position: 'relative', top: '4px', marginLeft: '1rem', marginRight: '1rem'}} />More Links
                    </Typography>
                    <Divider sx={{borderColor: '#ffffff'}} />
                    <List>
                        {morelinks.map((i, k) => (
                            <ListItem key={k} disablePadding>
                                <ListItemButton sx={{ textTransform: 'capitalize', fontSize: '14px', color: '#ffffff', textAlign: 'left', py: 1, px: 2}}>
                                    <ListItemIcon sx={{color: '#ffffff'}}>
                                        {i.icon}
                                    </ListItemIcon>
                                    <ListItemText sx={{marginLeft: '-0.8rem'}} primary={i.lable} />
                                </ListItemButton>
                            </ListItem> 
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} sm={12} md={2} mt={{xs: -3, md: 6}} > 
                    <List>
                        {morelinksnew.map((i, k) => (
                            <ListItem key={k} disablePadding>
                                <ListItemButton sx={{ textTransform: 'capitalize', fontSize: '14px', color: '#ffffff', textAlign: 'left', py: 1, px: 2}}>
                                    <ListItemIcon sx={{color: '#ffffff'}}>
                                        {i.icon}
                                    </ListItemIcon>
                                    <ListItemText sx={{marginLeft: '-0.8rem'}} primary={i.lable} />
                                </ListItemButton>
                            </ListItem> 
                        ))}
                    </List>
                </Grid>  
            </Grid>
            <Grid container> 
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className='copyright'>
                        {'Copyright © '}
                        {/* <Link color="inherit" href="."> */}
                            Memorypower
                        {/* </Link> */}
                        {' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography> 
                    <ScrollTop {...props}>
                        <Fab size="small" aria-label="scroll back to top" sx={{backgroundColor: '#ff9b56', color: '#ffffff'}}>
                            <KeyboardArrowUpIcon />
                        </Fab>
                    </ScrollTop>
                </Grid>  
            </Grid>
        </Box>
    </Fade>
  )
}

export default Footer;