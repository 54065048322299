import React from 'react';
import Founder from './Founder/Founder';
import Quality from './Quality/Quality';
import AboutUs from './AboutUs/AboutUs';
import Faq from './Faq/Faq';

const MeetFounder = () => {
    return (
        <React.Fragment>
            <Founder />
            <Quality/>
            <AboutUs />
            <Faq />
        </React.Fragment>
    )
}

export default MeetFounder