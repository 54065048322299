import React, { useState } from "react";
import './aboutSlider.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Img1 from '../../img/course/Mask Group1.png';
import Img2 from '../../img/course/Mask Group2.png';
import Img3 from '../../img/course/Mask Group3.png'; 

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
 
const AboutSlider = () => {
  return (  
    <Carousel showArrows={true} autoPlay={true} emulateTouch={true} infiniteLoop={true} className="AboutSlider">
      <div>
        <img src={Img1} alt='' />
        {/* <p className="legend">Legend 1</p> */}
      </div>
      <div>
        <img src={Img2} alt='' />
      </div>
      <div>
        <img src={Img3} alt='' />
      </div> 
    </Carousel>
  );
}

export default AboutSlider;