import React from "react";

import ContactHeaderPNG from "./../../../img/contact-header.png";
import { Box, Container, Stack, Typography } from "@mui/material";
import "./ContactHeader.css";

export default function ContactHeader() {
  return (
    <Box className="secondary contact-header section">
      <Container className="main-container">
        <Stack spacing={3} className="stack contact-content">
          {/* <img src={ContactHeaderPNG} style={{ visibility: "hidden" }} /> */}
          <Typography variant="h3" className="heading contact-heading">
            Our teams are here to help
          </Typography>
          <Typography variant="body1" className="contact-description">
            Questions, bug reports, feedback — we’re here for it all.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
