import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import SchoolIcon from '@mui/icons-material/School';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import HouseIcon from '@mui/icons-material/House';
import CallIcon from '@mui/icons-material/Call';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MenuIcon from '@mui/icons-material/Menu';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Logo from '../../img/logo.png';
import Favicon from '../../img/favicon.ico';
import {Link} from "react-router-dom"
import './header.css';

// mobile
const drawerWidth = 240;
const mobilemenu = [
  {lable : 'Home', icon : <HouseIcon />,link:"/"}, 
  {lable : 'About Us', icon : <AutoAwesomeOutlinedIcon />,link:"/"},
  {lable : 'Meet Founder', icon : <AdminPanelSettingsIcon />,link:"/meet-founder"},
  {lable : 'Our Certificates', icon : <MilitaryTechIcon />,link:"/"},
  {lable : 'Courses', icon : <SchoolIcon />,link:null},
  {lable : 'Contact Us', icon : <CallIcon />,link:"/contact-us"}
];

// desktop
const pages = ['About Us', 'Courses']; 
const aboutussubmenu = [
  {title: 'Meet Founder',link:"/meet-founder" },
  {title: 'Our Certificates',link:"/" },
];
const courcessubmenu = [
  {title: 'Fundamentals Of Memory',link:"/" },
  {title: 'Comprehensive Memory', link:"/" },
  {title: 'Panoramic Memory', link:"/" },
];
// const courcessubmenu = ['Fundamentals Of Memory', 'Comprehensive Memory', 'Panoramic Memory'];

const socials = [<FacebookIcon />, <InstagramIcon />, <TwitterIcon />];

function Header (props) {

  // desktop menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opensubmenu = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget); 
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // mobile responsive
  const {window} = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const drawer = (
    <Box sx={{ textAlign: 'center', height: '100%'}}>
      <Typography variant="h6" sx={{ my: 2, textTransform: 'capitalize', color: 'rgb(107 196 252)'}}>
        <img src={Favicon} alt="logo" style={{marginRight: '1rem'}} />Memory Power
      </Typography>
      <Divider />
      <List sx={{height: '60%', overflowY: 'scroll', p: 0 }}>
        {mobilemenu.map((item, k) => (
          <ListItem 
          component={Link}
          to={item.link} 
          key={k}
           disablePadding 
           onClick={item.lable === 'Courses' ? null : handleDrawerToggle}>
            <ListItemButton sx={{ textTransform: 'capitalize', fontSize: '14px', color: 'rgb(255 156 88)', textAlign: 'left', py: 1, px: 2, borderTop: k > 0 ? '1px solid rgba(0, 0, 0, 0.12)' : '0px'}}>
              {item.lable !== 'Courses' ?  
                <React.Fragment>
                  <IconButton sx={{color: 'rgb(255 156 88)', mr: 1}} >
                    {item.icon}
                  </IconButton>
                  <ListItemText primary={item.lable} />
                </React.Fragment>
              :
                <Accordion style={{boxShadow: 'none', width:'100%', p:0, m:0}}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"  
                    className='mobileaccordion' 
                  > 
                    <IconButton sx={{color: 'rgb(255 156 88)', mr: 1}} >
                      {item.icon}
                    </IconButton>
                    <ListItemText primary={item.lable} sx={{textTransform: 'capitalize', fontSize: '14px', color: 'rgb(255 156 88)', position: 'relative', top: '6px'}} />
                  </AccordionSummary>
                  <AccordionDetails sx={{p:0,display: "grid"}}>
                    {courcessubmenu.map((i, k) => (
                      <Typography 
                      component={Link}
                      to={i.link}
                        key={k}
                        sx={{py: 1.5, px: 1, color: '#333', fontSize: '14px', borderTop: '1px solid rgba(0, 0, 0, 0.12)',textDecoration:"none"}}>
                        - {i.title}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              }
            </ListItemButton>
          </ListItem>
        ))}
      </List> 
      <Divider />
      <Stack direction="column" spacing={1} p={2} sx={{position: 'relative'}}>
        <Button variant="outlined" startIcon={<AccountTreeIcon />} sx={{textTransform: 'capitalize', borderColor: 'rgb(107 196 252)', color: 'rgb(107 196 252)'}} onClick={handleDrawerToggle} href='https://memorypowerbackenddashboard.rabs.support/register' target="_blank">
          Join Class
        </Button>
        {/* <small style={{position: 'relative', top: '-0.4rem', color: 'rgb(107 196 252)', fontWeight: 400}}>Already booked ?</small> */}
        <Button variant="outlined" startIcon={<EventAvailableIcon />} sx={{textTransform: 'capitalize', borderColor: 'rgb(107 196 252)', color: 'rgb(107 196 252)'}} onClick={handleDrawerToggle} href='https://memorypowerbackenddashboard.rabs.support/register' target="_blank">
          Book A Free Trial
        </Button>
      </Stack>
      {socials.map((val, k) => (
        <IconButton key={k} sx={{color: 'rgb(249 148 211)'}} >
          {val}
        </IconButton> 
      ))}
    </Box>
  );
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Fade in timeout={800}>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed"> 
          <Toolbar disableGutters variant="dense" className='header'> 
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              <img src={Logo} alt="logo" />
            </Typography>
            {/* desktop menu */}
            <Box 
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, flexBasis: 'auto', flexFlow: 'wrap', flexDirection: 'row', justifyContent: 'right'}} 
              mr={3} 
              pt={0.3}
            >
              <Button 
                startIcon={<HouseIcon />} 
                variant="text" size="large" sx={{mr:1, textTransform: 'capitalize', fontWeight: 'bold', color: 'rgb(107 196 252)', fontSize: '14px'}}
                value="Home"
                component={Link}
                to="/"
              >
                Home
              </Button>
              {pages.map((item, k) => (
                <Button 
                  startIcon={k < 1 ? <AutoAwesomeOutlinedIcon /> : <SchoolIcon />} 
                  variant="text" size="large" key={k} sx={{mr:1, textTransform: 'capitalize', fontWeight: 'bold', color: 'rgb(107 196 252)', fontSize: '14px'}}
                  onClick={handleOpen}
                  value={item}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {item}
                </Button>
              ))}
              <Button 
                startIcon={<CallIcon />} 
                variant="text" size="large" sx={{mr:2, textTransform: 'capitalize', fontWeight: 'bold', color: 'rgb(107 196 252)', fontSize: '14px'}}
                value="Home"
                component={Link}
                to="/contact-us"
              >
                Contact Us
              </Button>
              {/* submenu */}
              <Menu
                anchorEl={anchorEl}
                id="submenu"
                open={opensubmenu}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 3,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} 
              >
                {anchorEl !== null && anchorEl.value === 'About Us' ? 
                  aboutussubmenu.map((val, k) => (
                    <MenuItem 
                      component={Link}
                      to={val.link}
                      key={k}
                      onClick={handleClose}
                      sx={{textTransform: 'capitalize', fontWeight: 500, color: 'rgb(107 196 252)', fontSize: '14px', padding: '12px 16px', borderTop: k > 0 ? '1px solid rgba(0, 0, 0, 0.12)' : '0px'}}>
                      <ListItemIcon>
                        <VerifiedIcon fontSize="small" sx={{color: 'rgb(107 196 252)'}} />
                      </ListItemIcon>
                      {val.title}
                    </MenuItem> 
                  ))
                : anchorEl !== null && anchorEl.value === 'Courses' ?
                  courcessubmenu.map((val, k) => (
                    <MenuItem 
                    component={Link}
                    to={val.link}
                    key={k} 
                    onClick={handleClose} 
                    sx={{textTransform: 'capitalize', fontWeight: 500, color: 'rgb(107 196 252)', fontSize: '14px', padding: '13px 16px', borderTop: k > 0 ? '1px solid rgba(0,0,0, .2)' : '0px'}}>
                      <ListItemIcon>
                        <SchoolIcon fontSize="small" sx={{color: 'rgb(107 196 252)'}} />
                      </ListItemIcon>
                      {val.title}
                    </MenuItem> 
                  ))
                : null} 
              </Menu>
              <Stack direction="row" spacing={3} mr={2} sx={{position: 'relative'}}>
                <Button variant="outlined" startIcon={<AccountTreeIcon />} sx={{textTransform: 'capitalize', borderColor: 'rgb(255 156 88)', color: 'rgb(255 156 88)'}} href='https://memorypowerbackenddashboard.rabs.support/register' target="_blank">
                  Join Class
                </Button>
                {/* <small style={{position: 'absolute', color: 'rgb(255 156 88)', left: '12px', padding: 0, margin: 0, bottom: '-20px', opacity: '0.7', fontWeight: 500}}>Already booked ?</small> */}
                <Button variant="outlined" startIcon={<EventAvailableIcon />} sx={{textTransform: 'capitalize', borderColor: 'rgb(255 156 88)', color: 'rgb(255 156 88)'}} href='https://memorypowerbackenddashboard.rabs.support/register' target="_blank">
                  Book A Free Trial
                </Button>
              </Stack>
              {socials.map((val, k) => (
                <IconButton key={k} sx={{color: 'rgb(249 148 211)'}} >
                  {val}
                </IconButton> 
              ))}
            </Box> 

            {/* mobile menu */}
            <Typography
              variant="img"
              noWrap
              // component=""
              // href="/"
              sx={{ 
                ml: 1,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1, 
              }}
            >
              <img src={Logo} alt="logo" style={{width: '140px', padding: '5px'}} />
            </Typography>
            <IconButton 
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box component="nav">
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
              >
                {drawer}
              </Drawer>
            </Box>
          </Toolbar>  
        </AppBar>
        {/* backto top */}
        <Toolbar id="back-to-top-anchor" /> 
      </Box>
    </Fade>
  );
}

Header.propTypes = {
  /* Injected by the documentation to work in an iframe. You won't need it on your project. */
  window: PropTypes.func,
};

export default Header;