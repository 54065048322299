import React, { Suspense,useState } from 'react';
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Alert from '@mui/material/Alert';
import Home from './pages/home';
import Header from './pages/header/header';
import Footer from './pages/footer/footer';
import MeetFounder from './pages/MeetFounder/MeetFounder';
import ContactUs from './pages/ContactUs/ContactUs';
// import Error from './Pages/';

function App() {

  const [loading, setLoading] = useState(true)
  const spinner = document.getElementById("spinner")

  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none"
      setLoading(false)
    }, 200);
  }

  return (
    <React.Fragment>
      <Router>
      <Header />
        <Suspense fallback={<Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>} >
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/meet-founder" element={<MeetFounder />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="*" element={<Navigate component={Link} replace to="home" />} />
          </Routes>
        </Suspense>
        <Footer/>
      </Router>
    </React.Fragment>
  );
}

export default App;