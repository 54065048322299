import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { DescriptionBody, Heading2 } from "../Common";
import "./Button.css";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    width={props.width}
    {...props}
  />
))(({ theme, width }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    // minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
    marginTop: "1px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      width: width,
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const EffectButton = (props) => {
  return (
    <Button
      variant="contained"
      className="effect-btn"
      type="submit"
      sx={props.inlinecss}
      startIcon={props.startIcon}
      onClick={props.onClick}
    >
      {" "}
      {props.text}
    </Button>
  );
};
export const ContainedButton = (props) => {
  return (
    <Button
      component={Link}
      to="https://memorypowerbackenddashboard.rabs.support/register"
      variant="contained"
      className="contained-btn"
      startIcon={props.StartIcon}
      endIcon={props.EndIcon}
      sx={{ marginTop: props.mtprops }}
    >
      {" "}
      {props.text}
    </Button>
  );
};
export const BookTrailLink = (props) => {
  return (
    <Link
      variant="contained"
      className="effect-btn"
      component={Link}
      to={props.linkto}
      sx={{ width: props.widthprops, color: props.colorprops }}
    >
      {" "}
      <CalendarMonthIcon /> &nbsp; Book Free Trail
    </Link>
  );
};
export const CustomContainedButton = (props) => {
  return (
    <Button
      variant="contained"
      className="custom-contained-btn"
      to={props.linkto}
      startIcon={props.StartIcon}
      endIcon={props.EndIcon}
      sx={{
        marginTop: props.mtprops,
        margin: props.marginprops,
        color: props.colorprops,
        backgroundColor: props.bgprops,
        width: props.widthprops,
      }}
    >
      {" "}
      {props.text}
    </Button>
  );
};
export const OutlinedButton = (props) => {
  return (
    <Button
      variant="outlined"
      className="outlined-btn"
      to={props.linkto}
      onClick={props.onClick}
      sx={{ width: props.widthprops }}
      startIcon={props.StartIcon}
      endIcon={props.EndIcon}
    >
      {" "}
      {props.text}
      <span> &nbsp;&nbsp;{props.highlightText}</span>
    </Button>
  );
};
export const RoundedContainedButton = (props) => {
  return (
    <Link
      variant="contained"
      className="rounded-contained-btn"
      to={props.linkto}
    >
      {" "}
      {props.text}
      <span>{props.highlightText}</span>
    </Link>
  );
};
export const CallBackContainedButton = (props) => {
  return (
    <Link
      variant="contained"
      className="callback-contained-btn"
      to="https://memorypowerbackenddashboard.rabs.support/register"
      startIcon={props.StartIcon}
      endIcon={props.EndIcon}
      sx={{ marginTop: props.mtprops }}
    >
      {" "}
      {props.text}
    </Link>
  );
};
export const HeaderContainedbtnGrp = (props) => {
  return (
    <>
      <Box className="contained-btn-wrapper">
        <Button
          variant="contained"
          className="contained-btn"
          component={Link}
          to={props.linkto}
          startIcon={props.StartIcon}
          endIcon={props.EndIcon}
          sx={{ marginTop: props.mtprops }}
        >
          {" "}
          {props.text}
        </Button>
        <Typography className="btn-desc">Already book a class ?</Typography>
      </Box>
    </>
  );
};

export const HeaderOutlinebtnGrp = (props) => {
  return (
    <>
      <Box className="contained-btn-wrapper">
        <Button
          variant="outlined"
          className="outlined-btn "
          component={Link}
          to={props.linkto}
          startIcon={props.StartIcon}
          endIcon={props.EndIcon}
          sx={{ marginTop: props.mtprops }}
        >
          {" "}
          <span className="btn">{props.text}</span>
        </Button>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography className="btn-desc hover-underline-animation">
            Already book a class ?
          </Typography>
        </Box> */}
      </Box>
    </>
  );
};

export const CustomBtn = (props) => {
  return (
    <>
      <Button
        variant="contained"
        component={NavLink}
        to={props.linkto}
        className="custom-btn"
        startIcon={props.StartIcon}
        endIcon={props.EndIcon}
        sx={{ marginTop: props.mtprops }}
      >
        {" "}
        {props.text}
      </Button>
    </>
  );
};

export const DropDownMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        className="no-border-outlined-btn submenu-dropdown btn"
        onClick={handleClick}
        sx={{ width: props.widthprops, ...props.css }}
        startIcon={props.StartIcon}
        endIcon={props.EndIcon}
      >
        <DescriptionBody
          fontSize="15px"
          bold
          className="hover-underline-animation"
        >
          {props.text}
        </DescriptionBody>
        <span> {props.highlightText}</span>
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        width={props.text === "Curriculum + Pricing" ? "180px" : "300px"}
      >
        {props.SubMenuList.map((row, id) => {
          return (
            <MenuItem
              component={NavLink}
              to={row.linkto}
              onClick={handleClose}
              key={id}
              className="header-submenu"
            >
              {row.icon}
              <span onClick={props.onClick}>{row.submenu}</span>
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};

export const ContainedButtonTwo = ({ children, btnText, ...props }) => {
  return (
    <Button
      component={Link}
      to="https://memorypowerbackenddashboard.rabs.support/register"
      target="_blank"
      variant="contained"
      sx={{
        backgroundColor: "#95bc17",
        padding: "10px 25px !important",
        borderRadius: "10px",
        width: "max-content",
        textTransform: "none",
        boxShadow: "none !important",
        fontWeight: 700,
        ...props,
      }}
      className="btn contained-button"
    >
      {children ? children : btnText}
    </Button>
  );
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 55,
    height: 30,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      transform: "translateX(26px)",
      "&.Mui-checked": {
      transform: "translateX(0px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#6cc5ff" : "#6cc5ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
        // transform: "translateX(26px)",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#6cc5ff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
      // transform: "translateX(26px)",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      // transform: "translateX(26px)",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 25,
    height: 26,
  },
  "& .MuiSwitch-track": {
    borderRadius: 35 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    // transform: "translateX(26px)",
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const MemorySwitch = () => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" mt={1}>
      <Heading2 fontSize="16px" color="#000">
        For Kids
      </Heading2>
      <IOSSwitch defaultChecked />
      <Heading2 fontSize="16px" color="#000">
        For Adults (+18)
      </Heading2>
    </Stack>
  );
};