import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import brainOne from "./../../../img/1.png";
import brainTwo from "./../../../img/2.png";
import brainThree from "./../../../img/3.png";
import circle from "./../../../img/4.png";

import { OutlineButton } from "./../../../components/Common";
import Message from "../Message/Message";
import "./Faq.css";

export default function Faq() {
  return (
    <Box className="secondary faq section">
      <Container className="main-container">
        <Stack spacing={5}>
          <Stack spacing={2} className="faq-stack">
            <AvatarGroup max={3}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
              <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
              <Avatar
                alt="Trevor Henderson"
                src="/static/images/avatar/5.jpg"
              />
            </AvatarGroup>
            <Typography variant="h6" className="heading">
              Still have questions?
            </Typography>
            <Typography variant="body1" className="faq-description">
              Cannot find the answer you are looking for? Please chat with our
              friendly team.
            </Typography>
            <OutlineButton>Get in touch</OutlineButton>
          </Stack>

          <Message />
        </Stack>
      </Container>

      <img src={circle} className="circle" />
      <img src={circle} className="circle-2" />

      {/* <div className="area"> */}
      <ul className="circles">
        <li>
          <img src={brainOne} className="brain-one" />
        </li>
        <li>
          <img src={brainTwo} className="brain-two" />
        </li>
        <li>
          <img src={brainThree} className="brain-three" />
        </li>
        <li>
          <img src={brainOne} className="brain-one" />
        </li>
        <li>
          <img src={brainTwo} className="brain-two" />
        </li>
        <li>
          <img src={brainThree} className="brain-three" />
        </li>
        <li>
          <img src={brainOne} className="brain-one" />
        </li>
        <li>
          <img src={brainTwo} className="brain-two" />
        </li>
        <li>
          <img src={brainThree} className="brain-three" />
        </li>
      </ul>
      {/* </div> */}
    </Box>
  );
}
