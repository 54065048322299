import React from 'react';
import './body.css';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import BgBlinks from '../bgBlinks/bgBlinks';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import {
  Heading2,
  Description,
  SectionContainer,
  DescriptionBody,
  MarginWrapper,
} from "../../components/Common";
import {
  ContainedButtonTwo,
  MemorySwitch,
} from "../../components/Button/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import AboutSlider from '../aboutSlider/aboutSlider';
import Services from '../../components/OfferCard/OfferCard';
import Button from '@mui/material/Button';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import LeftIcon from '../../img/lefticon.png';
import OurCourse from '../../components/OurCourse/OurCourse';
import Logicsicon from '../../img/icons/logics.png';
import Structureicon from '../../img/icons/structure.png';
import Creativeicon from '../../img/icons/creativeThinking.png';
import Sequencingicon from '../../img/icons/sequence.png';
import Algorithmicicon from '../../img/icons/algorithmicThinking.png';
import Homebg1 from '../../img/about-company/aboutCompany2.png';
import Homebg2 from '../../img/about-company/aboutCompany.png';
import Homebg3 from '../../img/about-company/aboutCompany3.png';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Bar from '../../components/Bars/Bar';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ClasssesLevel = [
  { ClassStd: "1" },
  { ClassStd: "2" },
  { ClassStd: "3" },
  { ClassStd: "4" },
  { ClassStd: "5" },
  { ClassStd: "6" },
  { ClassStd: "7" },
  { ClassStd: "8" },
  { ClassStd: "9" },
  { ClassStd: "10" },
  { ClassStd: "11" },
  { ClassStd: "12" },
];

// 
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Body = () => {
  const brains = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [ClassStd, setSelected] = React.useState(null);
  const toggle = (ClassStd) => { 
    setSelected(ClassStd);
  };

  // 
  const [tabvalue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  return (
    <Fade in timeout={800}>
    <Box p={0} sx={{minHeight: {md: '100vh'}}}>
      
      {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }} >
        <Grid item xs={12} md={12} sx={{marginTop: '-4rem'}}>
          <BgBlinks />
        </Grid>
      </Grid> */}

      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} justifyContent='center' className='bodyfirstsec' >
        <Grid item xs={12} md={4} sx={{minHeight: {md: '100vh'}, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} >
          <Typography variant="h4" component="h4" sx={{textAlign: 'center'}}>
            Online Education Courses
          </Typography>
          <Typography variant="h5" component="h5" pl={1} sx={{textAlign: 'center'}}>
            ''For Everyone''
          </Typography> 
          <br />
          <Box className="classes-card" mt={1}> 
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center", 
              }}
            > 
              <Typography variant="h5" component="h5" >
                Book your free class now
              </Typography>
              <MemorySwitch />
            </Box>
            <DescriptionBody fontSize="14px" mt={1} >
              Select your child's grade/class in school
            </DescriptionBody>
            {/* <Box className="classes-grade-table" my={2}> */}
            <Grid container mt={2} mb={2.5}>
              {ClasssesLevel.map((row, id) => {
                return (
                  // <Box className="classes-grade-card" key={id}>
                  <Grid
                    item
                    lg={2}
                    md={3}
                    xs={4}
                    className={
                      row.ClassStd === ClassStd
                        ? "classes-grade-card active"
                        : "classes-grade-card"
                    }
                    key={id}
                    onClick={() => toggle(row.ClassStd)}
                  >
                    <DescriptionBody fontSize="13px">
                      Class
                    </DescriptionBody>
                    {/* <Description description={row.ClassStd} /> */}
                    <DescriptionBody
                      fontSize="16px"
                      fontWeight={500}
                    >
                      {row.ClassStd}
                    </DescriptionBody>
                  </Grid>
                  // </Box>
                );
              })}
            </Grid>
            {/* </Box> */}
            <ContainedButtonTwo
              // padding="0.45rem 0 !important"
              width="100% !important"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0.45rem 0 !important",
                }}
              >
                <CalendarMonthIcon
                  className="calendar-icon"
                  style={{ height: "1.5rem", width: "1.5rem" }}
                />
                <DescriptionBody
                  bold
                  color="#fff"
                  fontSize="16px"
                  ml={2}
                >
                  Schedule a FREE Class
                </DescriptionBody>
              </Box>
            </ContainedButtonTwo>
          </Box> 
          <br />
        </Grid>
        <Grid item md={1} />
        <Grid item xs={12} md={4} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', position: 'relative'}} className='bodyfirstsecimg'>
          <img src={Homebg3} alt='' style={{position: 'absolute', width: '100%'}}/>
          <img src={Homebg1} alt="" className='imgone' /> 
          <img src={Homebg2} alt="" className='imgtwo' />          
        </Grid>
        <ul className="circles">
          {brains.map((b) => (
            <li key={b} />
          ))}
        </ul> 
      </Grid>

      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} sx={{md: {minHeight: '80vh'}, textAlign: 'center', alignItems: 'center'}} justifyContent='center' className='aboutbg' >
        
        <Grid item xs={0} md={4} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}} className='desktopslider'>
          <AboutSlider />
        </Grid> 
        <Grid item md={1} style={{maxWidth: '6%'}} />
        <Grid item xs={12} md={5} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left', alignItems: 'left'}}>
          <Box sx={{ width: '100%', md: {marginTop: '-3rem'} }} className='responsivepadding'>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabvalue} onChange={handleChange} textColor="primary" indicatorColor="primary" aria-label="secondary tabs" > 
                {/* centered */}
                <Tab label="Overview" {...a11yProps(0)} className='tabtitle' />
                <Tab label="Our Vision" {...a11yProps(1)} className='tabtitle' /> 
              </Tabs>
            </Box> 
            <TabPanel value={tabvalue} index={0} className='tabpanel'>  
              “THE MEMORY POWER” is an EdTech startup company that equips students with the skillset and mindset required to be the future of the companies worldwide. We connect a deep understanding of education with the power of the “21st CENTURY LIFE SKILLS” to make your child ready to face this competitive and challenging world.
              <br /><br />
              The universe is a subject created in the minds of humans addressed by the facts and theories since centuries. To think about it in detail requires the strength of knowledge and experience which are used to lead an intellectual life.
            </TabPanel>
            <TabPanel value={tabvalue} index={1} className='tabpanel'> 
              To help your child enhance the understanding of life based on the core concept of the most powerful tool in the world - “THE MEMORY POWER”.
              <br /><br />
              “Memory Power” is an art of practicing the life skills that will provide your children with the important tools for the development of brain, such as deep thinking, making new friends, decision making and taking actions in situations where their parents or teachers are not around to help.
            </TabPanel>  
          </Box>
          {/* <br /> */}
          {/* <Button variant="contained" size="large" sx={{color: '#ff9b56', textTransform: 'capitalize', backgroundColor: '#ffffff', fontWeight: '600', mx: 2}}>
            <EventAvailableOutlinedIcon />&nbsp;Book Free Trail
          </Button> */}
        </Grid>  
        <Grid item xs={12} className='mobileslider'>
          <AboutSlider />
        </Grid> 
      </Grid>

      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} justifyContent='center' className='servicebg'>
        <Grid item xs={12} md={12} sx={{minHeight: {md: '100vh'}, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
          <Typography variant="h4" component="h4" >
            Services we offer ?
          </Typography> 
          <br />
          <Services />
        </Grid> 
      </Grid>

      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} justifyContent='center' className='aboutbg'>
        <Grid item md={4} sx={{minHeight: {md: '100vh'}, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}} className='desktopbars'>
          <Bar />
        </Grid> 
        <Grid item xs={12} md={7} pr={3} sx={{minHeight: {md: '100vh'}, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left', alignItems: 'left'}} className='responsivepadding'>
          <Typography variant="h4" component="h4" p={1}>
            How do we prepare our kids for the better future ?
          </Typography> 
          <Divider sx={{width: '100%', my: 0.8, borderColor: '#333', opacity: '.2'}} />
          <Typography variant="h6" component="h6" p={1}>
            Here is a better way to build the mindsets of our kids to lead businesses of the future. This course has been carefully designed to enrich students with the skills needed for them to thrive as creative thinkers, great leaders, public speakers and leading life like “Entrepreneurs”.
            <Divider sx={{borderColor: 'transparent', my: 0.6}} />
            Each module of the course contains fun & engaging assignments that help our students learn the subject matter better through practical implementation. This also helps schools and parents assess improvement and see tangible results of the student’s learning.
          </Typography> 
        </Grid> 
        <Grid item xs={12} md={4} sx={{minHeight: {md: '100vh'}, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}} className='mobilebars'>
          <Bar />
        </Grid> 
      </Grid>
        
      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} justifyContent='center' className='learningbg'>
        <Grid p={2} item xs={12} md={12} sx={{minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
          <Typography variant="h4" component="h4" sx={{color: '#ffffff'}}>
            Your child will learn
          </Typography>
          <Divider sx={{width: '30%', my: 0.8, borderColor: '#ffffff', opacity: '.2'}} />
          <Typography variant="h6" component="h6" sx={{color: '#ffffff'}}>
            The Memory power that good questions drive good answers. Whether it's a query about the world around us or a challenge.
          </Typography> 
          <Box sx={{mt: '3rem', width: '100%', display: 'flex', justifyContent: 'space-around', padding: '0 6rem'}} className='responsiveview'>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div className='icons'><img src={Logicsicon} alt='' /></div>
              <span style={{color: '#ffffff', fontSize: '20px'}}>Logics</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div className='icons'><img src={Structureicon} alt='' /></div>
              <span style={{color: '#ffffff', fontSize: '20px'}}>Structure</span>
            </div> 
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div className='icons'><img src={Creativeicon} alt='' /></div>
              <span style={{color: '#ffffff', fontSize: '20px'}}>Creative Thinking</span>
            </div> 
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div className='icons'><img src={Sequencingicon} alt='' /></div>
              <span style={{color: '#ffffff', fontSize: '20px'}}>Sequencing</span>
            </div> 
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div className='icons'><img src={Algorithmicicon} alt='' /></div>
              <span style={{color: '#ffffff', fontSize: '20px'}}>Algorithmic Thinking</span>
            </div> 
          </Box>
        </Grid> 
      </Grid>

      <Grid container mb={-1} columns={{ xs: 12, sm: 12, md: 12 }} sx={{minHeight: {md: '100vh'}}} justifyContent='center' className='courcebg'>
        <Grid item pt={3} xs={12} md={12} sx={{display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'end'}}>
          <Typography variant="h4" component="h4" sx={{color: '#333'}} >
            Our Courses
          </Typography>
        </Grid>  
        <OurCourse /> 
      </Grid>
      
      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} justifyContent='center' className='bottombg'>
        <Grid item xs={12} mt={2} md={3} sx={{minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
          <img src={LeftIcon} alt="" />
          <Typography variant="h4" component="h4" pt={2} pl={2} sx={{color: '#ffffff', position: 'absolute'}}>
            Memory Power <br /> Management <br /> Team
          </Typography>  
        </Grid>
        <Grid item md={1} />
        <Grid item xs={12} md={5} sx={{minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
          <Typography variant="h6" component="h6" sx={{color: '#ffffff'}}>
            Top 1% teachers selected by an industry-leading 4-step teacher assessment process by top 1% of early childhood experts.
            <br />
            Memory power follows a rigorous 4-step Selection Process for confirming teacher candidates so your childern are taught by the top early childhood experts.
          </Typography>  
          <br /> 
          <div className='responsivebtns'>
            <Button variant="contained" size="large" sx={{color: '#ff9b56', textTransform: 'capitalize', backgroundColor: '#ffffff', fontWeight: '600', mx: 2}}>
              <PlayCircleFilledWhiteOutlinedIcon />&nbsp;Live Comments
            </Button>
            <Button variant="contained" size="large" sx={{color: '#ff9b56', textTransform: 'capitalize', backgroundColor: '#ffffff', fontWeight: '600', mx: 2}} href="https://memorypowerbackenddashboard.rabs.support/register" target='_blank'>
              <EventAvailableOutlinedIcon />&nbsp;Book Free Trail
            </Button>
          </div>
        </Grid> 
      </Grid>

    </Box>
    </Fade>
  )
}

export default Body;