import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import brainOne from "../../img/bar/brainOne.png";
import brainTwo from "../../img/bar/brainTwo.png";
import brainThree from "../../img/bar/brainThree.png";
import brainFour from "../../img/bar/brainFour.png";
import "./Bar.css";
import { Heading2 } from "../Common";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        // tooltip: {
        //   overrides tooltip
        //   zIndex: 0 + "!important",
        // },
        popper: {
          // overrides the popper
          zIndex: 0 + "!important",
        },
      },
    },
  },
});

export default function Bar() {
  const [open, setOpen] = useState({
    tooltipOne: true,
    tooltipTwo: true,
    tooltipThree: true,
    tooltipFour: true,
  });

  const handleTooltipOneToggle = () => {
    setOpen((prev) => ({ ...prev, tooltipOne: !prev.tooltipOne }));
  };
  const handleTooltipTwoToggle = () => {
    setOpen((prev) => ({ ...prev, tooltipTwo: !prev.tooltipTwo }));
  };
  const handleTooltipThreeToggle = () => {
    setOpen((prev) => ({ ...prev, tooltipThree: !prev.tooltipThree }));
  };
  const handleTooltipFourToggle = () => {
    setOpen((prev) => ({ ...prev, tooltipFour: !prev.tooltipFour }));
  };
  const barTooltips = [
    {
      name: "tooltipOne",
      number: "2400",
      age: "15yrs to 18yrs",
      isOpen: open.tooltipOne,
      placement: "top-end",
      brain: brainOne,
      controller: handleTooltipOneToggle,
    },
    {
      name: "tooltipTwo",
      number: "800",
      age: "12yrs to 14yrs",
      isOpen: open.tooltipTwo,
      placement: "top-end",
      brain: brainTwo,
      controller: handleTooltipTwoToggle,
    },
    {
      name: "tooltipThree",
      number: "1908",
      age: "6yrs to 8yrs",
      isOpen: open.tooltipThree,
      placement: "top",
      brain: brainThree,
      controller: handleTooltipThreeToggle,
    },
    {
      name: "tooltipFour",
      number: "10098",
      age: "9yrs to 11yrs",
      isOpen: open.tooltipFour,
      placement: "top-start",
      brain: brainFour,
      controller: handleTooltipFourToggle,
    },
  ];
  const [timer, setTimer] = useState(0); 
  React.useEffect(() => {
    setTimeout(() => {
      setTimer(1); 
    }, 2500); 
  }, [timer])

  return (
    <ThemeProvider theme={theme}>
      <div className="bar-main">
        <div className="top-div">
          <div className="main-div">
            {barTooltips.map((barTooltip, k) => (
              <Tooltip
                key={k}
                TransitionComponent={Zoom}
                open={barTooltip.isOpen}
                PopperProps={{
                  // disablePortal: true,
                  popperOptions: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      // {
                      //   name: "offset",
                      //   options: {
                      //     offset: [0, -8],
                      //   },
                      // },
                    ],
                  },
                }}
                title={
                  <Box
                    sx={{
                      color: "#333"
                    }}
                    className={timer === 1 ? `${barTooltip.name}` : 'dnone'}
                  >
                    <img src={barTooltip.brain} alt={barTooltip.name} />
                    <Heading2 variant="h5" bold color="#000">
                      {barTooltip.number}
                    </Heading2>
                    <Typography variant="subtitle2">
                      {barTooltip.age}
                    </Typography>
                  </Box>
                }
                enterDelay={200}
                //   leaveDelay={2000}
                placement={barTooltip.placement}
              >
                <div
                  className="bar"
                  // onMouseEnter={
                  //   barTooltip.name === "tooltipThree"
                  //     ? barTooltip.controller
                  //     : null
                  // }
                  // onMouseLeave={
                  //   barTooltip.name === "tooltipThree"
                  //     ? barTooltip.controller
                  //     : null
                  // }
                >
                  <div className="face"></div>
                  <div className="face"></div>
                  <div
                    className="face"
                    // onMouseOver={barTooltip.controller}
                    // onMouseLeave={barTooltip.controller}
                  ></div>
                  <div
                    className="face"
                    // onMouseOver={barTooltip.controller}
                    // onMouseLeave={barTooltip.controller}
                  ></div>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}