import React from 'react';
import Body from './body/body'; 

function Home() {
  return (
    <React.Fragment>
      
      <Body />
    </React.Fragment>
  )
}

export default Home;