import React from "react";
import Box from "@mui/material/Box";
import ContactHeader from "./ContactHeader/ContactHeader";
import ContactBox from "./ContactBox/ContactBox";
import ContactForm from "./Contact-form/Contact-form";
import Location from "./Location/Location";

export default function Contact() {
  return (
    <Box sx={{mt:2}}>
      <ContactHeader />
      <ContactBox />
      <Location />
      <ContactForm />
    </Box>
  );
}
