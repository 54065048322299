import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import MyLocationIcon from "@mui/icons-material/MyLocation";

import "./Location.css";
import MapLocation from "./Map-location";

export default function Location() {
  return (
    <Box className="secondary section">
      <Container className="main-container">
        <Grid container className="location-main" rowSpacing={8}>
          <Grid item md={6}>
            <Stack spacing={2}>
              <Typography variant="body1" className="heading">
                Contact Us
              </Typography>
              <Typography variant="h4" className="location-header">
                Our Locations
              </Typography>
              <Typography variant="body2" className="location-description">
                Come visit our friendly team at one of our offices.
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Stack spacing={4}>
              <Stack direction="row" spacing={4} alignItems="center">
                <MyLocationIcon className="location-icon" />
                <Stack spacing={1}>
                  <Typography variant="h6" className="heading">
                    Mumbai
                  </Typography>
                  <Typography variant="body1">
                    123 Main Street Anytown, Fictional County
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={4} alignItems="center">
                <MyLocationIcon className="location-icon" />
                <Stack spacing={1}>
                  <Typography variant="h6" className="heading">
                    Delhi
                  </Typography>
                  <Typography variant="body1">
                    456 Elm Avenue Dreamville, Fantasy County Magical State,
                    54321
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={12} className="map-location">
            {/* <MapLocation /> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d235.583491565087!2d72.848652!3d19.13677!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b62cfff81d39%3A0x9351320a5fc9c38c!2sAbba%20Residency!5e0!3m2!1sen!2sid!4v1686143110708!5m2!1sen!2sid"
              width="600"
              height="450"
              frameBorder="0"
              style={{
                border: 0,
                width: "100%",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.2) 1px 1px 7px",
              }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
