import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { LinkHover } from "./../../../components/Common";

import "./ContactBox.css";

const contact = [
  {
    heading: "All Courses",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque maximus eros risus, eu blandit purus consectetur et. Phasellus consequat justo sit amet nunc malesuada mattis. Cras eget mi eu odio.",
    linkMessage: "Browse all courses",
  },
  {
    heading: "Our teams",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque maximus eros risus, eu blandit purus consectetur et. Phasellus consequat justo sit amet nunc malesuada mattis. Cras eget mi eu odio.",
    linkMessage: "Browse all teams",
  },
  {
    heading: "Get to know us",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque maximus eros risus, eu blandit purus consectetur et. Phasellus consequat justo sit amet nunc malesuada mattis. Cras eget mi eu odio.",
    linkMessage: "Browse our Talent Community",
  },
];

export default function ContactBox() {
  return (
    <Box className="primary contact-box section">
      <Container className="main-container">
        <Grid container spacing={4}>
          {contact.map((item, i) => {
            return (
              <Grid item key={i} md={4} xs={12}>
                <Box className="content-box">
                  <Stack spacing={3}>
                    <Typography variant="h4" className="heading">
                      {item.heading}
                    </Typography>
                    <Typography variant="body1">{item.description}</Typography>
                    <LinkHover>{item.linkMessage}</LinkHover>
                  </Stack>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}
