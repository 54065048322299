/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

import "./Dialog.css";
import { Grid, Typography } from "@mui/material";
import { LinkHover } from "../Common";

export default function DialogOpen(props) {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={props.open}
        onClose={props.handleClose}
      >
        <Box className="dialog-main">
          <Grid container spacing={5}>
            <Grid
              item
              md={4}
              sx={{
                paddingTop: "0px !important",
                paddingLeft: { md: "40px !important", xs: "0px !important" },
              }}
            >
              <Box className="dialog-header">
                <img src={props.bgImage}></img>
                <Stack spacing={1} className="dialog-header-stack">
                  <Typography variant="h6" className="heading">
                    {props.firstName}
                  </Typography>
                  <Typography variant="body1" className="dialog-role">
                    {props.role}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Button className="dialog-icon-button">
                      <TwitterIcon className="dialog-icon" />
                    </Button>
                    <Button className="dialog-icon-button">
                      <FacebookIcon className="dialog-icon" />
                    </Button>
                  </Stack>
                  <LinkHover />
                </Stack>
              </Box>
            </Grid>
            <Grid
              item
              md={8}
              sx={{
                paddingTop: "0px !important",
              }}
            >
              <Box className="content-block">
                <Typography variant="body1" className="display-linebreak">
                  {props.description}
                </Typography>
              </Box>
              <DialogActions>
                <Button className="close-btn" onClick={props.handleClose}>
                  Close
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
}
