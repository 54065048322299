import { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import FounderOne from "./../../../img/team/Anu.png";
import FounderTwo from "./../../../img/team/Scott.png";
import FounderThree from "./../../../img/team/Mike.png";
import FounderOneHover from "./../../../img/team/Anu-hover.png";
import FounderTwoHover from "./../../../img/team/Scott-hover.png";
import FounderThreeHover from "./../../../img/team/Mike-hover.png";
import DialogOpen from "./../../../components/Dialog/Dialog";
import { LinkHover } from "./../../../components/Common";
import "./Founder.css";

export default function Founder() {
  //   const [visibility, setVisibility] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedItem(null);
    setOpen(false);
  };

  const user = [
    {
      id: 1,
      backgroundImage: FounderOne,
      hoverImage: FounderOneHover,
      firstName: "Sophia Patel",
      role: "CO-FOUNDER & CO-CEO",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at.",
    },
    {
      id: 2,
      backgroundImage: FounderTwo,
      hoverImage: FounderTwoHover,
      firstName: "Benjamin Harrison",
      role: "Chairman",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at.",
    },
    // {
    //   id: 3,
    //   backgroundImage: FounderThree,
    //   hoverImage: FounderThreeHover,
    //   firstName: "William Reynolds",
    //   role: "President",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at.",
    // },
    // {
    //   id: 4,
    //   backgroundImage: FounderTwo,
    //   hoverImage: FounderTwoHover,
    //   firstName: "Ava Johnson",
    //   role: "Chairman",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at.",
    // },
    // {
    //   backgroundImage: FounderThree,
    //   hoverImage: FounderThreeHover,
    //   firstName: "Henry Cooper",
    //   role: "President",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquam ultrices neque diam, eu aliquet diam efficitur at. \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec ultrices odio, in faucibus quam. Mauris accumsan ex et hendrerit dictum. Morbi nisi nulla, porta eu volutpat eget, iaculis sed augue. Aliquamultrices neque diam, eu aliquet diam efficitur at.",
    // },
  ];

  return (
    <Box className="primary section">
      <Container className="main-container">
        <Grid container spacing={5}>
          <Grid item md={6} className="team-intro">
            <Stack spacing={4}>
              <Typography variant="h4" className="heading">
                Meet our Founders
              </Typography>
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                vitae congue quam. Nullam in aliquam urna. Cras scelerisque
                ultrices laoreet. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Fusce vitae congue quam. Nullam in aliquam
                urna. Cras scelerisque ultrices laoreet.
              </Typography>
              <LinkHover>
                Find your people, your answers, your path to growth
              </LinkHover>
            </Stack>
          </Grid>
          {user.map((item, id) => {
            return (
              <Grid item md={3} sm={6} xs={12} key={id}>
                <Box className="founder-container">
                  <Box className="background-image">
                    <a onClick={() => handleItemClick(item)}>
                      <img src={item.backgroundImage} />
                    </a>
                  </Box>
                  <Box className={`invisible-image`}>
                    <a onClick={() => handleItemClick(item)}>
                      <img src={item.hoverImage} />
                    </a>
                  </Box>
                  <Stack spacing={1}>
                    <Typography variant="h6" className="heading">
                      {item.firstName}
                    </Typography>
                    {/* <Divider
                      sx={{
                        borderBottomWidth: 2,
                        backgroundColor: "rgb(25, 118, 210)",
                        width: "80px",
                      }}
                    ></Divider> */}
                    <Typography variant="body1" className="founder-role">
                      {item.role}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {selectedItem !== null ? (
          <DialogOpen
            open={open}
            handleClose={handleDialogClose}
            description={selectedItem.description}
            firstName={selectedItem.sfirstName}
            role={selectedItem.role}
            bgImage={selectedItem.backgroundImage}
          />
        ) : null}
      </Container>
    </Box>
  );
}
