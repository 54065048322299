import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import circle from "./../../../img/4.png";
import React from "react";

import "./Contact-form.css";

export default function ContactForm() {
  return (
    <Box className="primary section">
      <Container className="main-container">
        <Grid container className="contact-form">
          <Grid item md={5} className="contact-details">
            <Stack spacing={8}>
              <Stack spacing={1}>
                <Typography variant="h4" className="heading">
                  Contact Information
                </Typography>
                <Typography variant="body1">
                  Say something to start the conversation
                </Typography>
              </Stack>
              <Stack spacing={4}>
                <Stack direction="row" alignItems="center" spacing={4}>
                  <PhoneInTalkIcon />
                  <Typography variant="body1">+91 000 000 2222</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={4}>
                  <EmailIcon />
                  <Typography variant="body1">demo@gmail.com</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={4}>
                  <FmdGoodIcon />
                  <Typography variant="body1">
                    123 Main Street Anytown, Fictional County Imaginary State,
                    98765 United States
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} className="contact-icons">
                <Button className="contact-icon">
                  <TwitterIcon />
                </Button>
                <Button className="contact-icon">
                  <InstagramIcon />
                </Button>
                <Button className="contact-icon">
                  <WhatsAppIcon />
                </Button>
              </Stack>
            </Stack>
            <img src={circle} className="contact-details-circle" />
          </Grid>
          <Grid item md={7} className="contact-fields">
            <Grid container rowSpacing={6} columnSpacing={6}>
              <Grid item md={6}>
                <TextField
                  id="standard-basic"
                  label="First Name"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  id="standard-basic"
                  label="Last Name"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  id="standard-basic"
                  label="Phone Number"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <FormControl fullWidth sx={{ rowGap: 2 }}>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="heading"
                  >
                    Select Subject?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="general-enquiry-1"
                      control={<Radio />}
                      label="General Enquiry"
                    />
                    <FormControlLabel
                      value="general-enquiry-2"
                      control={<Radio />}
                      label="General Enquiry"
                    />
                    <FormControlLabel
                      value="general-enquiry-3"
                      control={<Radio />}
                      label="General Enquiry"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-textarea"
                  label="Message"
                  placeholder="Placeholder"
                  multiline
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className="contact-submit">
                <Button variant="contained" className="contact-submit-button" sx={{margin:"auto"}}>
                  <Typography variant="body1">Send Message</Typography>
                </Button>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
