import React from "react";
import { Box, Divider } from "@mui/material";
import { DescriptionBody, DescriptionTitle, Title } from "../Common";
import "./OurCourse.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Card1PNG from "../../img/course/Mask Group1.png";
import Card2PNG from "../../img/course/Mask Group2.png";
import Card3PNG from "../../img/course/Mask Group3.png";
import BrainPNG from "../../img/course/brain.png";
import { ContainedButtonTwo } from "../Button/Button";
import { UtilityCard } from "../Common";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const courses = [
  {
    img: Card1PNG,
    heading: "FUNDAMENTAL OF MEMORY",
    classes: "8 classes",
    price: "9,599",
  },
  {
    img: Card2PNG,
    heading: "COMPREHENSIVE MEMORY",
    classes: "50 classes",
    price: "49,999",
  },
  {
    img: Card3PNG,
    heading: "PANAROMIC MEMORY",
    classes: "100 classes",
    price: "1,35,999",
  },
];

const OurCourse = () => {
  return (
    courses.map((course, i) => (
      <Grid item key={i} xs={12} sm={12} md={3} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
        <div style={{position: 'relative'}}>
          <div className="ribbon ribbon-top-left"><span>{course.classes}</span></div>
            <Card sx={{ maxWidth: 345 }} className="mycard">
              <CardMedia
                sx={{ height: 200 }}
                image={ course.img } 
              />
              <CardContent sx={{position: 'relative', pb: 1}}>
                <Typography gutterBottom variant="h6" component="h6">
                  {course.heading}
                </Typography>
                Curriculum Includes  
                <Typography variant="body2" color="text.secondary" >
                  Sequence, Fundamentals Coding Blocks, Loops
                </Typography>  
                <Divider sx={{mb: 2, opacity: '0'}} />  
                <Typography variant="h5" component="h5" sx={{mb: 0}}>
                  ₹&nbsp;{course.price}&nbsp;/-
                </Typography>
              </CardContent>
              <CardActions sx={{mx: '1rem', mb: '1rem', display: 'flex', flexBasis: '100%', flexDirection: 'column'}}>
                <Button variant="contained" size="large" sx={{color: '#ff9b56', textTransform: 'capitalize', backgroundColor: '#ffffff', width: '100%', mb: 2}} endIcon={<img src={BrainPNG} width='30px' alt="" />}>
                  View Details
                </Button>
                <Button variant="contained" size="large" sx={{color: '#ff9b56', textTransform: 'capitalize', backgroundColor: '#ffffff', fontWeight: '600', width: '100%'}} style={{marginLeft: '0'}} href="https://memorypowerbackenddashboard.rabs.support/register" target='_blank'>
                  <CalendarMonthIcon />&nbsp;Book Free Trail
                </Button>
              </CardActions>
            </Card>
        </div>
      </Grid>
    ))
  );
};
 
export default OurCourse;